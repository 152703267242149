import React from "react";
import { ReactComponent as RightCircle } from "../assets/icons/right-circle.svg";
import styled from "styled-components/macro";
import People from "../assets/images/people.svg";

const TextLink = styled.p`
  :hover {
    svg {
      left: 0.8rem;
    }
  }
`;

const WhoAreWe = () => {
  return (
    <section
      className="py-20 px-16 md:px-32 md:py-40 flex flex-col gap-10 md:gap-0 md:flex-row w-full bg-blue-primary"
      aria-label="section-who-are-we"
      id="about-us"
    >
      <div className="w-full md:w-1/2 flex flex-col md:justify-start justify-center md:items-start items-center text-center md:text-left">
        <div className="w-full max-w-[20rem] border-t border-white mb-6"></div>

        <h2
          className="text-3xl md:text-5xl text-white mb-8 word-spacing-1"
          aria-label="section-header"
        >
          Who We Are
        </h2>

        <article
          className="text-silver-primary text-lg md:w-[300px] leading-relaxed tracking-wide"
          aria-label="section-body"
        >
          <p className="mb-10">
            Whogohost is the largest and most popular cloud solutions provider
            in West Africa. With over 15 years of experience, we offer a wide
            range of products and services to meet the varied needs of our
            customers.
          </p>
          <p className="mb-6">
            You can never be wrong to start business with us.
          </p>

          <TextLink className="text-white flex hover:underline underline-offset-2 cursor-pointer w-fit items-center mx-auto md:m-0">
            Learn More
            <RightCircle className="ml-4 relative w-8 h-8 left-0 transition-5-ease" />
          </TextLink>
        </article>
      </div>
      <div className="w-full md:w-1/2 flex justify-center md:justify-end">
        <img
          src={People}
          alt="connected people"
          loading="lazy"
          className="w-full"
        />
      </div>
    </section>
  );
};

export default WhoAreWe;
