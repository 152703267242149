import React from "react";
import "./style.css";

const Hamburger = ({ showSideNav, setShowSideNav }) => {
  return (
    <div className="md:hidden">
      <input
        type="checkbox"
        id="checkbox"
        name="checkbox"
        className="checkbox visuallyHidden"
        checked={showSideNav}
        onChange={() => setShowSideNav((state) => !state)}
      />
      <label htmlFor="checkbox">
        <div className="hamburger">
          <span className="bar bar1"></span>
          <span className="bar bar2"></span>
          <span className="bar bar3"></span>
          <span className="bar bar4"></span>
          <span className="bar bar5"></span>
        </div>
      </label>
    </div>
  );
};

export default Hamburger;
