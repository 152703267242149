import React from "react";
import features from "../fixtures/fetaures";

const WhyChooseUs = () => {
  return (
    <section
      className="py-20 px-16 md:py-36 md:px-32 flex flex-col justify-center items-center md:items-start md:justify-start w-full text-center md:text-left"
      aria-label="section-why-choose-us"
      id="features"
    >
      <div className="w-full max-w-[30rem] border-t border-blue-dark mb-10"></div>
      <h2
        className="text-3xl md:text-5xl text-blue-darker mb-8 word-spacing-1"
        aria-label="section-header"
      >
        Why Choose Us
      </h2>

      <div
        className="py-10 w-full grid grid-cols-1 md:grid-cols-3 grid-flow-row gap-24"
        aria-label="section-body"
      >
        {features.map(({ title, icon: Icon, description }) => (
          <div
            className="flex flex-col justify-center items-center md:items-start md:justify-start p-4"
            key={title}
          >
            <span className="mb-8">
              <Icon className="w-20 h-20" />
            </span>
            <h3 className="text-black-primary text-xl mb-3">{title}</h3>
            <p className="text-black-light text-[1.4rem]">{description}</p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default WhyChooseUs;
