import React, { useState } from "react";
import { ReactComponent as Logo } from "../assets/images/logo.svg";
import { Link } from "react-router-dom";
import * as ROUTES from "../routes";
import { NavButton } from "./buttons";
import Hamburger from "./hamburger";
import Overlay from "./overlay";
import LockBody from "./lockBody";

const navLinks = [
  {
    name: "Features",
    link: ROUTES.FEATURES
  },
  {
    name: "About Us",
    link: ROUTES.ABOUT
  },
  {
    name: "Contact Us",
    link: ROUTES.CONTACT
  }
];

const Header = () => {
  const [showSideNav, setShowSideNav] = useState(false);

  const handleClose = () => {
    setShowSideNav(false);
  };

  return (
    <header className="px-5 sm:px-8 md:px-10 lg:px-16` bg-white flex flex-row justify-between items-center">
      <div className="flex items-center justify-center px-2 sm:px-4 md:px-6 lg:px-8">
        <Link to={ROUTES.HOME}>
          <Logo className="w-40 h-20 md:w-fit md:h-fit" />
        </Link>
      </div>
      <div className="flex relative">
        <nav
          className={`fixed z-20 md:sticky bg-white h-screen md:h-fit ${
            showSideNav ? "left-0" : "-left-80"
          } top-0 w-80 md:w-fit transition-5-ease-in-out`}
        >
          <Link to={ROUTES.HOME}>
            <Logo className="md:hidden w-[10rem] ml-8" />
          </Link>
          <div className="flex flex-col md:flex-row">
            <ul className="flex flex-col md:flex-row items-center list-none divide-y divide-silver-light md:divide-y-0">
              {navLinks.map(({ name, link }) => (
                <li
                  className="py-5 md:py-2 px-8 md:px-6 text-blue-light text-[1.4rem] sm:text-lg md:text-center w-full md:w-fit"
                  key={name}
                >
                  <a
                    href={link}
                    className="hover:text-blue-primary transition-all"
                    onClick={handleClose}
                  >
                    {name}
                  </a>
                </li>
              ))}
            </ul>
            <div className="flex flex-row py-8 md:py-2 px-8 md:px-4 w-full md:w-fit border-t border-silver-light md:border-none">
              <NavButton />
            </div>
          </div>
        </nav>
        <Hamburger showSideNav={showSideNav} setShowSideNav={setShowSideNav} />
      </div>
      {showSideNav && (
        <>
          <Overlay onClose={handleClose} />
          <LockBody />
        </>
      )}
    </header>
  );
};

export default Header;
